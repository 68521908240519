import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateTask from './CreateTask';
import CreateBulkTasks from './CreateBulkTasks';
import styles from './TaskManager.module.css';
import FormatTasks from './FormatTasks';

const TaskManager = () => {
    const [allTasks, setAllTasks] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [newResponsibleId, setNewResponsibleId] = useState('');
    const [newAuditors, setNewAuditors] = useState('');
    const [newCreatorId, setNewCreatorId] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [changeLog, setChangeLog] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const tasksPerPage = 50;
    const [searchTitle, setSearchTitle] = useState('');

    const [newTaskTags, setNewTaskTags] = useState('');
    const [newTaskTimeTracking, setNewTaskTimeTracking] = useState(false);
    const [newTaskResultRequired, setNewTaskResultRequired] = useState(false);
    const [newParentId, setNewParentId] = useState('');
    const [newGroupId, setNewGroupId] = useState('');

    const fetchTasks = useCallback(async () => {
        let start = 0;
        const allFetchedTasks = [];
        let hasMoreTasks = true;

        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

        try {
            while (hasMoreTasks) {
                const response = await axios.get('/api/tasks', {
                    params: {
                        projectId,
                        title: searchTitle, // Используем значение searchTitle из состояния
                        start: start,
                        limit: 20
                    }
                });
                const fetchedTasks = response.data;
                allFetchedTasks.push(...fetchedTasks);

                if (fetchedTasks.length < 20) {
                    hasMoreTasks = false;
                } else {
                    start += 20;
                    await delay(500);
                }
            }

            setAllTasks(allFetchedTasks);
            setTasks(allFetchedTasks.slice(0, 20));
            setCurrentPage(0);
        } catch (error) {
            toast.error('Error fetching tasks: ' + (error.response?.data?.message || error.message));
        }
    }, [projectId, searchTitle]);

    const fetchChangeLog = useCallback(async () => {
        try {
            const response = await axios.get('/api/changelog');
            setChangeLog(response.data.reverse()); // Сортировка изменений в обратном порядке
        } catch (error) {
            toast.error('Error fetching change log: ' + error.response?.data?.message || error.message);
        }
    }, []);

    useEffect(() => {
        fetchChangeLog();
    }, [fetchChangeLog]);

    const handleTaskSelect = (taskId) => {
        setSelectedTasks(prevSelectedTasks =>
            prevSelectedTasks.includes(taskId)
                ? prevSelectedTasks.filter(id => id !== taskId)
                : [...prevSelectedTasks, taskId]
        );
    };

    const handleDeselectAll = () => {
        setSelectedTasks([]);
    };

    const updateSelectedTasks = async () => {
        try {
            const auditorsArray = newAuditors ? newAuditors.split(',').map(id => parseInt(id.trim(), 10)) : undefined;
            const tagsArray = newTaskTags ? newTaskTags.split(',').map(tag => tag.trim()) : undefined;
            await Promise.all(selectedTasks.map(taskId => {
                const fieldsToUpdate = {};
                if (newResponsibleId) fieldsToUpdate.responsibleId = newResponsibleId;
                if (auditorsArray) fieldsToUpdate.auditors = auditorsArray;
                if (newCreatorId) fieldsToUpdate.createdBy = newCreatorId;
                if (newParentId) fieldsToUpdate.parentId = newParentId;
                if (newGroupId) fieldsToUpdate.groupId = newGroupId;
                if (tagsArray) fieldsToUpdate.tags = tagsArray;
                if (newTaskTimeTracking !== undefined) fieldsToUpdate.timeTracking = newTaskTimeTracking ? 'Y' : 'N';
                if (newTaskResultRequired !== undefined) fieldsToUpdate.resultRequired = newTaskResultRequired;

                return axios.post('/api/tasks/update', { taskId, ...fieldsToUpdate });
            }));
            toast.success('Selected tasks updated successfully');
            fetchTasks();
            fetchChangeLog();
        } catch (error) {
            toast.error('Error updating tasks: ' + error.response?.data?.message || error.message);
        }
    };

    const deleteSelectedTasks = async () => {
        try {
            await Promise.all(selectedTasks.map(taskId =>
                axios.delete('/api/tasks/delete', {
                    data: { taskId }
                })
            ));
            toast.success('Selected tasks deleted successfully');
            fetchTasks();
            fetchChangeLog();
        } catch (error) {
            toast.error('Error deleting tasks: ' + error.response?.data?.message || error.message);
        }
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        setCurrentPage(0);
        if (query) {
            const filteredTasks = allTasks.filter(task =>
                task.title.toLowerCase().includes(query.toLowerCase()) ||
                task.description.toLowerCase().includes(query.toLowerCase())
            );
            setTasks(filteredTasks.slice(0, tasksPerPage));
        } else {
            setTasks(allTasks.slice(0, tasksPerPage));
        }
    };

    const handleNextPage = () => {
        const nextPage = currentPage + 1;
        const start = nextPage * tasksPerPage;
        const end = start + tasksPerPage;
        setTasks(allTasks.slice(start, end));
        setCurrentPage(nextPage);
    };

    const handlePreviousPage = () => {
        const prevPage = currentPage - 1;
        const start = prevPage * tasksPerPage;
        const end = start + tasksPerPage;
        setTasks(allTasks.slice(start, end));
        setCurrentPage(prevPage);
    };

    return (
        <div className={styles.container}>
            <ToastContainer />
            <h1 className={styles.title}>Менеджер задач Bitrix24</h1>
            <Tabs>
                <TabList>
                    <Tab>Редактирование задач</Tab>
                    <Tab>Создать задачу</Tab>
                    <Tab>Массовое создание задач</Tab>
                    <Tab>Форматирование номеров</Tab>
                    <Tab>Журнал изменений</Tab>
                </TabList>

                <TabPanel>
                    <label className={styles.label}>
                        Проект ID:
                        <input
                            type="text"
                            value={projectId}
                            onChange={(e) => setProjectId(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        Заголовок задачи:
                        <input
                            type="text"
                            value={searchTitle}
                            onChange={(e) => setSearchTitle(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <button onClick={fetchTasks} className={styles.button}>Получить задачи</button>
                    <br />
                    <label className={styles.label}>
                        Новый постановщик ID:
                        <input
                            type="text"
                            value={newCreatorId}
                            onChange={(e) => setNewCreatorId(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        Исполнитель ID:
                        <input
                            type="text"
                            value={newResponsibleId}
                            onChange={(e) => setNewResponsibleId(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        Наблюдатели IDs:
                        <input
                            type="text"
                            value={newAuditors}
                            onChange={(e) => setNewAuditors(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        Тэги (через запятую):
                        <input
                            type="text"
                            value={newTaskTags}
                            onChange={(e) => setNewTaskTags(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        Учет времени:
                        <input
                            type="checkbox"
                            checked={newTaskTimeTracking}
                            onChange={(e) => setNewTaskTimeTracking(e.target.checked)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        Результат задачи:
                        <input
                            type="checkbox"
                            checked={newTaskResultRequired}
                            onChange={(e) => setNewTaskResultRequired(e.target.checked)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        Родительская задача ID:
                        <input
                            type="text"
                            value={newParentId}
                            onChange={(e) => setNewParentId(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        ID проекта:
                        <input
                            type="text"
                            value={newGroupId}
                            onChange={(e) => setNewGroupId(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <label className={styles.label}>
                        Поиск:
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            className={styles.input}
                        />
                    </label>
                    <br />
                    <button onClick={updateSelectedTasks} disabled={selectedTasks.length === 0} className={styles.button}>
                        Обновить выбранные задачи
                    </button>
                    <button onClick={deleteSelectedTasks} disabled={selectedTasks.length === 0} className={styles.button}>
                        Удалить выбранные задачи
                    </button>
                    <button onClick={handleDeselectAll} disabled={selectedTasks.length === 0} className={styles.button}>
                        Снять все выделения
                    </button>
                    <div className={styles.taskListContainer}>
                        <ul className={styles.taskList}>
                            {tasks.map((task) => (
                                <li key={task.id} className={styles.taskListItem}>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={selectedTasks.includes(task.id)}
                                            onChange={() => handleTaskSelect(task.id)}
                                            className={styles.checkbox}
                                        />
                                        {task.title}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.paginationControls}>
                        <button onClick={handlePreviousPage} className={styles.button} disabled={currentPage === 0}>
                            Предыдущая страница
                        </button>
                        <button onClick={handleNextPage} className={styles.button} disabled={(currentPage + 1) * tasksPerPage >= allTasks.length}>
                            Следующая страница
                        </button>
                    </div>
                </TabPanel>

                <TabPanel>
                    <CreateTask />
                </TabPanel>

                <TabPanel>
                    <CreateBulkTasks />
                </TabPanel>

                <TabPanel>
                    <FormatTasks />
                </TabPanel>

                <TabPanel>
                    <div className={styles.changeLogContainer}>
                        <h2>Журнал изменений</h2>
                        <ul className={styles.changeLogList}>
                            {changeLog.map((log, index) => (
                                <li key={index} className={styles.changeLogItem}>
                                    <div>
                                        <strong>{new Date(log.timestamp).toLocaleString()}</strong>
                                        <p><b>ID задачи:</b> {log.taskId}</p>
                                        <p><b>Заголовок задачи:</b> {log.taskTitle}</p>
                                        <p><b>Тип изменения:</b> {log.type}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default TaskManager;
