import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './CreateTask.module.css';

const CreateTask = () => {
    const [newTaskTitle, setNewTaskTitle] = useState('');
    const [newTaskDescription, setNewTaskDescription] = useState('');
    const [newResponsibleId, setNewResponsibleId] = useState('');
    const [newTaskDeadline, setNewTaskDeadline] = useState('');
    const [newTaskPriority, setNewTaskPriority] = useState('');
    const [newParentId, setNewParentId] = useState('');
    const [newGroupId, setNewGroupId] = useState('');
    const [newTaskTags, setNewTaskTags] = useState('');
    const [newTaskTimeTracking, setNewTaskTimeTracking] = useState(false);
    const [newTaskResultRequired, setNewTaskResultRequired] = useState(false);
    const [newAuditors, setNewAuditors] = useState('');

    const createTask = async () => {
        const auditorsArray = newAuditors ? newAuditors.split(',').map(id => id.trim()) : [];
        const tagsArray = newTaskTags ? newTaskTags.split(',').map(tag => tag.trim()) : [];

        try {
            const response = await axios.post('/api/tasks/create', {
                title: newTaskTitle,
                description: newTaskDescription,
                responsibleId: newResponsibleId,
                deadline: newTaskDeadline,
                priority: newTaskPriority,
                parentId: newParentId,
                groupId: newGroupId,
                auditors: auditorsArray,
                tags: tagsArray,
                timeTracking: newTaskTimeTracking,
                resultRequired: newTaskResultRequired
            });
            if (response.status === 200) {
                toast.success('Task created successfully');
            } else {
                toast.error('Error creating task');
            }
        } catch (error) {
            toast.error('Error creating task: ' + error.toString());
        }
    };

    return (
        <div className={styles.container}>
            <h2>Создание новой задачи</h2>
            <label className={styles.label}>
                Заголовок:
                <input
                    type="text"
                    value={newTaskTitle}
                    onChange={(e) => setNewTaskTitle(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Описание:
                <input
                    type="text"
                    value={newTaskDescription}
                    onChange={(e) => setNewTaskDescription(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Исполнитель ID<sup>*</sup>:
                <input
                    type="text"
                    value={newResponsibleId}
                    onChange={(e) => setNewResponsibleId(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Дедлайн:
                <input
                    type="date"
                    value={newTaskDeadline}
                    onChange={(e) => setNewTaskDeadline(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Приоритет:
                <select
                    value={newTaskPriority}
                    onChange={(e) => setNewTaskPriority(e.target.value)}
                    className={styles.input}
                >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                </select>
            </label>
            <br />
            <label className={styles.label}>
                Тэги (через запятую):
                <input
                    type="text"
                    value={newTaskTags}
                    onChange={(e) => setNewTaskTags(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Учет времени:
                <input
                    type="checkbox"
                    checked={newTaskTimeTracking}
                    onChange={(e) => setNewTaskTimeTracking(e.target.checked)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Результат задачи:
                <input
                    type="checkbox"
                    checked={newTaskResultRequired}
                    onChange={(e) => setNewTaskResultRequired(e.target.checked)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Наблюдатели (IDs через запятую):
                <input
                    type="text"
                    value={newAuditors}
                    onChange={(e) => setNewAuditors(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Родительская задача ID:
                <input
                    type="text"
                    value={newParentId}
                    onChange={(e) => setNewParentId(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                ID проекта:
                <input
                    type="text"
                    value={newGroupId}
                    onChange={(e) => setNewGroupId(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <button onClick={createTask} className={styles.button}>Создать задачу</button>
        </div>
    );
};

export default CreateTask;
