import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TaskManager from './TaskManager';
import CreateTask from './CreateTask';
import CreateBulkTasks from './CreateBulkTasks';
import FormatTasks from './FormatTasks';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/create-bulk-tasks" element={<CreateBulkTasks />} />
                    <Route path="/create-task" element={<CreateTask />} />
                    <Route path="/format-tasks" element={<FormatTasks />} />
                    <Route path="/" element={<TaskManager />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
