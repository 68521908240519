import React, { useState } from 'react';
import styles from './FormatTasks.module.css';

const FormatTasks = () => {
    const [inputTasks, setInputTasks] = useState('');
    const [formattedTasks, setFormattedTasks] = useState('');

    const specialNumbersVideo = [33, 50, 51, 18, 24, 26, 2, 25];
    const specialNumbersEOM = [16, 5, 20, 6, 12, 38];
    const specialNumbersInteractive = [7, 3, 40, 58, 29, 42, 56, 57, 59, 4];
    const defaultObserver = '2241';
    const defaultCreator = '1502';

    const handleTextareaChange = (e) => {
        setInputTasks(e.target.value);
    };

    const formatTaskNumbers = () => {
        const tasks = inputTasks.split(/[\n;]/).map(task => task.trim()).filter(task => task);
        let previousGroup = '';
        const formattedTasks = tasks.map(task => {
            const parts = task.split('-');
            if (parts.length >= 5) {
                const penultimateNumber = parseInt(parts[4], 10);
                const formattedTask = parts.join('-');

                const group = parts.slice(0, 3).join('-');
                let formattedResult;
                let observers = '';
                let creator = '';

                if (specialNumbersVideo.includes(penultimateNumber)) {
                    formattedResult = `2024 Задача на видео_${formattedTask}`;
                    observers = '1794';
                } else if (specialNumbersEOM.includes(penultimateNumber)) {
                    formattedResult = `2024 Задача на диагностический ЭОМ_${formattedTask}`;
                    observers = '1502';
                } else if (specialNumbersInteractive.includes(penultimateNumber)) {
                    formattedResult = `2024 Задача на интерактивное задание_${formattedTask}`;
                    observers = '1502';
                } else {
                    formattedResult = `2024 ${formattedTask}`;
                    observers = defaultObserver;
                    creator = defaultCreator;
                }

                if (group !== previousGroup) {
                    previousGroup = group;
                    return `\n${formattedResult},,,,${observers},${creator}`;
                } else {
                    return `${formattedResult},,,,${observers},${creator}`;
                }
            }
            return task;
        });

        setFormattedTasks(formattedTasks.join('\n').trim() + '\n'); // Добавляем пустую строку в конце
    };

    return (
        <div className={styles.container}>
            <h2>Форматирование номеров задач</h2>
            <textarea
                value={inputTasks}
                onChange={handleTextareaChange}
                placeholder="Введите номера задач, разделенные точкой с запятой"
                className={styles.textarea}
            />
            <button onClick={formatTaskNumbers} className={styles.button}>Форматировать задачи</button>
            <textarea
                value={formattedTasks}
                readOnly
                placeholder="Отформатированные номера задач"
                className={styles.textarea}
            />
        </div>
    );
};

export default FormatTasks;
