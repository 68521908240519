import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './CreateBulkTasks.module.css';

const CreateBulkTasks = () => {
    const [bulkTasks, setBulkTasks] = useState('');
    const [newTaskTags, setNewTaskTags] = useState('');
    const [newTaskTimeTracking, setNewTaskTimeTracking] = useState(false);
    const [newGroupId, setNewGroupId] = useState('');
    const [newTaskResultRequired, setNewTaskResultRequired] = useState(false);
    const [newTaskDeadline, setNewTaskDeadline] = useState('');
    const [newTaskPriority, setNewTaskPriority] = useState('low');
    const [highlightedField, setHighlightedField] = useState('');

    const handleTextareaChange = (e) => {
        const value = e.target.value;
        setBulkTasks(value);

        const position = e.target.selectionStart;
        const beforeCursor = value.slice(0, position);
        const line = beforeCursor.split('\n').pop();
        const columns = line.split(',');

        if (columns.length === 6) {
            setHighlightedField('Постановщик');
        } else if (columns.length === 5) {
            setHighlightedField('Наблюдатели');
        } else if (columns.length === 4) {
            setHighlightedField('ID Родительской задачи');
        } else if (columns.length === 3) {
            setHighlightedField('ID Исполнителя');
        } else if (columns.length === 2) {
            setHighlightedField('Описание');
        } else if (columns.length === 1) {
            setHighlightedField('Заголовок');
        } else {
            setHighlightedField('');
        }
    };

    const createBulkTasks = async () => {
        const tasks = bulkTasks.split('\n').map(line => {
            const [title, description, responsibleId, parentId, auditors, creator] = line.split(',').map(item => item.trim());
            return {
                title,
                description,
                responsibleId,
                parentId: parentId || undefined,
                groupId: newGroupId,
                auditors: auditors ? auditors.split('|').map(id => id.trim()) : [],
                createdBy: creator || undefined,
                tags: newTaskTags ? newTaskTags.split(',').map(tag => tag.trim()) : [],
                timeTracking: newTaskTimeTracking ? 'Y' : 'N',
                resultRequired: newTaskResultRequired ? 'Y' : 'N',
                deadline: newTaskDeadline,
                priority: newTaskPriority
            };
        });

        try {
            const responses = await Promise.all(tasks.map(task => axios.post('/api/tasks/create', task)));
            if (responses.every(response => response.status === 200)) {
                toast.success('Tasks created successfully');
            } else {
                toast.error('Error creating some tasks');
            }
        } catch (error) {
            toast.error('Error creating tasks: ' + error.toString());
        }
    };

    return (
        <div className={styles.container}>
            <ToastContainer />
            <h2>Массовое создание задач</h2>
            <label className={styles.label}>
                ID проекта:
                <input
                    type="text"
                    value={newGroupId}
                    onChange={(e) => setNewGroupId(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Тэги (через запятую):
                <input
                    type="text"
                    value={newTaskTags}
                    onChange={(e) => setNewTaskTags(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Учет времени:
                <input
                    type="checkbox"
                    checked={newTaskTimeTracking}
                    onChange={(e) => setNewTaskTimeTracking(e.target.checked)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Результат задачи:
                <input
                    type="checkbox"
                    checked={newTaskResultRequired}
                    onChange={(e) => setNewTaskResultRequired(e.target.checked)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Дедлайн:
                <input
                    type="date"
                    value={newTaskDeadline}
                    onChange={(e) => setNewTaskDeadline(e.target.value)}
                    className={styles.input}
                />
            </label>
            <br />
            <label className={styles.label}>
                Приоритет:
                <select
                    value={newTaskPriority}
                    onChange={(e) => setNewTaskPriority(e.target.value)}
                    className={styles.input}
                >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                </select>
            </label>
            <br />
            <label className={styles.label}>
                <p>Введите задачи, по одной на строку в формате:</p> 
                <p>
                    <span className={highlightedField === 'Заголовок' ? styles.highlight : ''}>Заголовок</span>, 
                    <span className={highlightedField === 'Описание' ? styles.highlight : ''}> Описание</span>, 
                    <span className={highlightedField === 'ID Исполнителя' ? styles.highlight : ''}> ID Исполнителя</span>, 
                    <span className={highlightedField === 'ID Родительской задачи' ? styles.highlight : ''}> ID Родительской задачи</span>, 
                    <span className={highlightedField === 'Наблюдатели' ? styles.highlight : ''}> Наблюдатели (через |)</span>, 
                    <span className={highlightedField === 'Постановщик' ? styles.highlight : ''}> Постановщик</span>
                </p>
                <textarea
                    value={bulkTasks}
                    onChange={handleTextareaChange}
                    placeholder="Пример: Заголовок задачи, Описание задачи, 1502, 0, 2429|2241, 1794"
                    className={`${styles.textarea}`}
                />
            </label>
            <br />
            <button onClick={createBulkTasks} className={styles.button}>Массовое создание задач</button>
        </div>
    );
};

export default CreateBulkTasks;
